import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyCg8z5Ttwq0TI2ip3UuDq4n_vkd5j1H2ks",
    authDomain: "zeyame-gold.firebaseapp.com",
    databaseURL: "https://zeyame-gold.firebaseio.com",
    projectId: "zeyame-gold",
    storageBucket: "zeyame-gold.appspot.com",
    messagingSenderId: "21599577800",
    appId: "1:21599577800:web:4c6b2efbbaaff1604fa7fd",
    measurementId: "G-J20M8CG00W"
  });
}


export const askForPermissioToReceiveNotifications = async () => {
  
    try {
      if (firebase.apps.length === 0) {initializeFirebase();}
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('token do usuário:', token);
      
      return token;

    } catch (error) {
        
      console.error(error);
    }
  }
  